<template>
  <div id="app" style="width: 100vw;height: 100vh; background: #000;box-sizing: border-box;">
    <div style="width: 100vw;display: flex;flex-direction: column;align-items: center;box-sizing: border-box;">
      <div style="font-size: 25px;padding: 10px 0;color: #fff;">
        The Lighthouse - Zijin Yang</div>
      <div class="vioce" style="max-width: 100vw;">
        <div>灯塔</div>
        <svg v-if="stoped" id="play" @click="play" viewBox="0 0 100 100"
          style="width: 25px;height: 25px;margin-left: 13px;padding-top: 2px;">
          <path d="M52.5,3.5a50,50,0,1,0,50,50A50.06,50.06,0,0,0,52.5,3.5Zm0,92a42,42,0,1,1,42-42A42,42,0,0,1,52.5,95.5Z"
            transform="translate(-2.5 -3.5)" />
          <path
            d="M70.21,50.31c-6-3.68-11.74-7.38-17.82-11.21l-7.62-4.7c-2.81-1.58-4.08-.79-4.6.16-.65,1.22-.58,3.25-.58,8.88v4.47l0,19c-.33,3.36.65,8.77,6.6,5.7l7.55-4.79c8.74-5.49,11.6-7,15.35-9.32l0-.16c4.95-2.49,4.51-5.89,1.06-8Z"
            transform="translate(-2.5 -3.5)" />
        </svg>
        <svg v-else id="stop" @click="play" viewBox="0 0 100 100"
          style="width: 25px;height: 25px;margin-left: 13px;padding-top: 2px;">
          <path d="M52.5,3.5a50,50,0,1,0,50,50A50.06,50.06,0,0,0,52.5,3.5Zm0,92a42,42,0,1,1,42-42A42,42,0,0,1,52.5,95.5Z"
            transform="translate(-2.5 -3.5)" />
          <rect x="55.03" y="31.66" width="10.34" height="36" />
          <rect x="34.3" y="31.66" width="10.34" height="36" />
        </svg>
      </div>
      <audio ref="audioPlayer" :src="'./dt.wav'" autoplay preload="none"></audio>
      <div style="max-width: 100vw;">
        <img v-for="(item, index) in imgList" :key="index" style="width: 100%;height: auto;" :src="item" alt="">
      </div>

    </div>
  </div>
</template>

<script>
// import pdf from 'vue-pdf'

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      stoped: true,
      imgList: ['src/gaiwande-1.png',
        'src/gaiwande-2.png',
        'src/gaiwande-3.png',
        'src/gaiwande-4.png',
        'src/gaiwande-5.png',
        'src/gaiwande-6.png',
        'src/gaiwande-7.png',
        'src/gaiwande-8.png',
        'src/gaiwande-9.png',
        'src/gaiwande-10.png',
        'src/gaiwande-11.png',
        'src/gaiwande-12.png',
        'src/gaiwande-13.png',
        'src/gaiwande-14.png',
        'src/gaiwande-15.png',
        'src/gaiwande-16.png',
        'src/gaiwande-17.png',
        'src/gaiwande-18.png',
        'src/gaiwande-19.png',
        'src/gaiwande-20.png',
        'src/gaiwande-21.png',
        'src/gaiwande-22.png',
        'src/gaiwande-23.png',
        'src/gaiwande-24.png',
        'src/gaiwande-25.png',
        'src/gaiwande-26.png',
        'src/gaiwande-27.png',
        'src/gaiwande-28.png',
        'src/gaiwande-29.png']
    }
  },
  created() {
    // this.audioPlayer = this.$refs.audioPlayer;
    // this.audioChunks = []; // 存储分段音频块的数组
    // this.currentChunkIndex = 0; // 当前播放的分段音频块索引
  },
  methods: {
    // play() {
    //   this.stoped = false
    //   // 如果还有未加载的分段音频块，则加载下一个分段
    //   if (this.currentChunkIndex < this.audioChunks.length) {
    //     this.loadNextChunk();
    //   } else {
    //     // 所有分段音频块已加载完毕，直接播放
    //     this.audioPlayer.play();
    //   }
    // },
    // pause() {
    //   this.stoped = true
    //   this.audioPlayer.pause();
    // },
    // loadNextChunk() {
    //   const nextChunk = this.audioChunks[this.currentChunkIndex];
    //   const objectUrl = URL.createObjectURL(nextChunk);
    //   this.audioPlayer.src = objectUrl;
    //   this.audioPlayer.load();
    //   this.audioPlayer.play();
    //   this.currentChunkIndex++;
    // },
    // fetchAudioChunks() {
    //   fetch('灯塔.wav')
    //     .then(response => response.blob())
    //     .then(blob => {
    //       const chunkSize = 1 * 1024 * 1024; // 每个分段音频块的大小（这里设置为1MB）
    //       // 将音频文件分割成块
    //       let offset = 0;
    //       while (offset < blob.size) {
    //         const chunk = blob.slice(offset, offset + chunkSize);
    //         this.audioChunks.push(chunk);
    //         offset += chunkSize;
    //       }
    //     });
    // },
    play() {
      const audioPlayer = this.$refs.audioPlayer;
      this.stoped = !this.stoped
      if (this.stoped) {
        audioPlayer.pause();
      } else {
        audioPlayer.play();
      }
    }
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;

}

body {
  margin: 0;
  padding: 0;
  background: #000;
  /* scrollbar-width: none !important;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none; */
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 100%;
  /* transition: all 0.3s ease; */
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  /* box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1); */
  background: #ffffff26;
  opacity: 0;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
	border-radius: 10px;
	background: #ffffff00; */
  opacity: 0;
}

.vioce {
  padding-bottom: 15px;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e5e5e5;
  line-height: 26px;
  cursor: pointer;
}

.vioce:hover {
  color: #ffffff;
}

.vioce svg path {
  fill: #e5e5e5;
}

.vioce:hover svg path {
  fill: #fff;
}

.vioce svg rect {
  fill: #e5e5e5;
}

.vioce:hover svg rect {
  fill: #fff;
}
</style>
